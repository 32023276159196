import React from 'react'
import dynamic from 'next/dynamic'

import {Brand} from '@daedalus/core/src/brand/types'

export interface PageProps {
  brand: Brand
}

/**
 * Note that all app providers are loaded client side to ease the migration from CRA.
 * To move to SSR this will need to be decoupled.
 */
const ClientAppRoutes = dynamic(() => import('../AppRoutes'), {
  ssr: false
})

/**
 * Note that all app providers are loaded client side to ease the migration from CRA.
 * To move to SSR this will need to be decoupled.
 */
const AppProviders = dynamic(() => import('../AppProviders'), {
  ssr: false
})

const Page = () => {
  return (
    <>
      <AppProviders>
        <ClientAppRoutes />
      </AppProviders>
    </>
  )
}

export default Page
